<template>
    <Guest :title="t('page_title')" logo>
        <div class="py-8">
            <form v-on:submit.prevent="handleSubmit">
                <TextInput v-model:value="form.email" v-model:errors="errors.email" :label="t('email')" :placeholder="t('email')" autofocus autocapitalize="none" />
                <TextInput v-model:value="form.password" v-model:errors="errors.password" :label="t('password')" :placeholder="t('password')" password class="mt-4" autocapitalize="none" />
                <div class="mt-6 flex items-center justify-between">
                    <div class="flex items-center">
                    <input id="remember_me" type="checkbox" v-model="remember_me" class="form-checkbox h-4 w-4 transition duration-150 ease-in-out">
                    <label for="remember_me" class="ml-2 block text-sm leading-5 text-gray-100">
                        {{ t('remember_me') }}
                    </label>
                    </div>

                    <div class="text-sm leading-5">
                    <router-link :to="{ name: 'password.forgot'}" class="text-gray-100 font-medium">
                        {{ t('forgot_password') }}
                    </router-link>
                    </div>
                </div>

                <div class="mt-6">
                    <span class="block w-full rounded-md shadow-sm">
                    <button type="submit" class="button w-full">
                        {{ t('login') }}
                    </button>
                    </span>
                </div>
                <div class="mt-6">
                    <router-link :to="{ name: 'register'}">
                        <span class="block w-full rounded-md shadow-sm">
                            <button type="submit" class="button-transparent w-full">
                                {{ t('create_account') }}
                            </button>
                        </span>
                    </router-link>
                </div>
            </form>
        </div>
  </Guest>
</template>

<script>
import { login } from "@/helpers/api/AuthHelper";
import Guest from "@/wrappers/Guest";
import TextInput from "@/components/forms/TextInput";
import useI18n from "@/modules/globalI18n";

export default {
    name: "Login",
    components:{
        Guest,
        TextInput
    },
    setup() {
        return useI18n({
            viewPrefix: "Login"
        });
    },
    data(){
        return {
            form: {
                email: "",
                password: "",
            },
            remember_me: false,
            errors: {
                email: [],
                password: [],
            }
        }
    },
    methods:{
        handleSubmit(){
            login(this.form, this.remember_me)
                .then(() => {
                    const nextRoute = this.$route.query.next ?? {name: 'dashboard'};
                    this.$router.push(nextRoute);
                })
                .catch(err => {
                    this.errors = err.errors;
                });
        },
    },
}
</script>